import React from 'react';
import '../../styles/main.css';
import '../../styles/gear.css';
import '../../styles/index.css';
import '../../styles/spider.css';
import github from '../../icons/github.png';
import linkedin from '../../icons/linkedin.png';

function Main() {
	return (
		<div className="Main">
			<div className="content">
				<h1>Working in progress...</h1>
				<span className="textBody">A personal website by IvanCarvalho</span>
				<div>
					<a className="linkedinIcon" href="https://www.linkedin.com/in/ivan-r-carvalho/">
						<img src={linkedin} alt="IvanCarvalho Linkedin" height="42" width="42"/>
					</a>
					<a href="https://github.com/ivancarvalhofilho">
						<img className="githubIcon" src={github} alt="IvanCarvalho GitHub" height="42"
							 width="42"/>
					</a>
				</div>
			</div>
			<div className="background">
				<div className="gearParent">
					<div className="gear">
						<div className="center"></div>
						<div className="tooth"></div>
						<div className="tooth"></div>
						<div className="tooth"></div>
						<div className="tooth"></div>
					</div>
				</div>
			</div>
			<div className="spider">
				<div className="spider-web"></div>
				<div className="container">
					<div className="arms">
						<div className="arm-container">
							<div className="arm"></div>
							<div className="arm"></div>
							<div className="arm"></div>
						</div>
						<div className="arm-container">
							<div className="arm"></div>
							<div className="arm"></div>
							<div className="arm"></div>
						</div>
					</div>
					<div className="spider-body">
						<div className="eye eye-left"></div>
						<div className="eye eye-right"></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Main;
